<template>
  <div class="achievementMain">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>我的成绩</el-breadcrumb-item>
    </el-breadcrumb>
    <div v-if="!errorflag">
      <div class=""
           v-for="(pitem,pindex) in scorelist"
           :key="pindex">
        <div class="achievement ">
          <div class="bgpurple padd">
            <div class="top">
              <p class="name"><span>{{pitem.classesinfo.Season}}{{pitem.classesinfo.SubSeason}}</span><span>{{pitem.classesinfo.Subject}}</span><span>{{pitem.classesinfo.GradeStart}}</span><span>{{pitem.classesinfo.ClassType}}</span> </p>
              <p><span>{{pitem.classesinfo.Year}} {{pitem.classesinfo.Season}}{{pitem.classesinfo.SubSeason}}</span><span>第 {{pitem.classscorecur.LessonNo}} 讲</span> </p>
            </div>
            <div class="selectmain">
              <el-select v-model="pitem.yearvalue"
                         placeholder="请选择"
                         @change="changeyear(pitem,pindex)">
                <el-option v-for="(item,index) in yearoptions"
                           :key="index"
                           :label="item"
                           :value="item">
                </el-option>
              </el-select>
              <el-select v-model="pitem.classvalue"
                         placeholder="请选择"
                         @change="changeLesson(pitem,pindex)">
                <el-option v-for="(item,index) in pitem.classoptions"
                           :key="index"
                           :label="item.text"
                           :value="item.value">
                </el-option>
              </el-select>
            </div>
            <p class="bold">成绩表</p>
          </div>
          <div class="bgfff padd">
            我的分数：<span class="bold">{{pitem.classscorecur.Score}}</span>
          </div>
          <div class="bgpurple padd average"  v-show="pitem.ScoreType=='all' || pitem.ScoreType=='avg'">
            <span class="averagetit">平均分数：</span>
            <p>班级：<span class="bold">{{pitem.classscorecur.ClassAvg}}</span>校区：<span class="bold">{{pitem.classscorecur.CampusAvg}}</span>全市：<span class="bold last">{{pitem.classscorecur.CityAvg}}</span></p>
          </div>
          <div class="bgpurple padd average"  v-show="pitem.ScoreType=='all'">
            <span class="averagetit">排名：</span>
            <p>班级：<span class="bold">{{pitem.classscorecur.ClassRanking}}</span>校区：<span class="bold">{{pitem.classscorecur.CampusRanking}}</span>全市：<span class="bold last">{{pitem.classscorecur.CityRanking}}</span></p>
          </div>
        </div>
        <p class="timetip"><span>*</span> 当前显示平均分和数据基于{{pitem.classesinfo.date1}}3点，下次更新时间{{pitem.classesinfo.date2}}3点。</p>
        <div class="errorlist"
             v-show="pitem.classscorecur.errorlist.length>0">
          <p class="bold bgpurple padd">逐题对错</p>
          <ul>
            <li v-for="(errorpitem,errorpindex) in pitem.classscorecur.errorlist"
                :key="errorpindex"
                :class="errorpindex%2==0?'bgfff':'bgpurple'"
                class="padd">
              <p v-for="(erroritem,errorindex) in errorpitem"
                 :key="errorindex">
                {{erroritem.Title}}:<i class="el-icon-check"
                   v-if="erroritem.ScoreTof==1"></i><i class="el-icon-close"
                   v-else></i>
              </p>
            </li>
          </ul>
        </div>
        <div class="bgpurple record" v-show="pitem.ScoreType=='all'">
          <p class="bold">学生成长记录</p>
          <p class="recordtit">{{pitem.classesinfo.Season}}{{pitem.classesinfo.SubSeason}}{{pitem.classesinfo.Subject}}{{pitem.classesinfo.GradeStart}}{{pitem.classesinfo.ClassType}}</p>
          <!-- <LineChart :id="'AverageStorge'+pindex"
                    :options="pitem.AverageStorge"
                    :class="'echart'+pindex"
                    ref="refvalue"></LineChart> -->
          <div v-if="hashook"
               class="roseChart"
               :id="'echart'+pindex"
               style="width:80%;height:400px"
               ref="refvalue">11</div>
          <p class="linetips">学员成长轨迹图 <span>（分值代表你超越了多少该组别的同学%）</span></p>
        </div>
      </div>
    </div>
    <div v-else
         class="errortips">{{errortips}}</div>
  </div>
</template>

<script>
import LineChart from "../../components/LineChart";
import { classes_score } from '@/api/classesinfo'
// import * as echarts from "echarts";
export default {
  name: 'achievement',
  components: {
    LineChart
  },
  data () {
    return {
      yearoptions: [],
      errorflag: false,
      errortips: '',
      scorelist: [],
      userid: this.$store.state.user.stu_user_info[0].id,
      hashook: false,
    };
  },
  computed: {
  },
  mounted () {
    this.$nextTick(() => {
      this.getInit()
    })
  },
  methods: {
    //初始化数据
    getInit () {
      let prames = {
        userid: this.userid,
        YearSeason: ''
      }
      this.initdata(prames)
    },
    async initdata (prames) {
      this.hashook = false;
      let res = await classes_score(prames)
      if (res.flag == '1') {
        this.yearoptions = res.datainfo.yearseason;
        let score = res.datainfo.score;
        score.forEach((item, index) => {
          let lessonarr = JSON.parse(JSON.stringify(item.Lessonarr));
          lessonarr.sort(function (a, b) { return b - a; });
          let classoptions = lessonarr.map(ele => {
            return {
              text: '第' + ele + '讲',
              value: ele
            }
          })
          let classvalue = classoptions[0].value;
          let classscorecur = {};
          var errorlist = [];
         
          item.classscore.forEach(ele => {
            if (classvalue == ele.LessonNo) {
              classscorecur = ele;
              if (ele.ScoreQbq.length > 0) {
                for (var i = 0; i < ele.ScoreQbq.length; i = i + 4) {
                  errorlist.push(ele.ScoreQbq.slice(i, i + 4));
                }
              }

            }
          })
          classscorecur.errorlist = errorlist;
          this.$set(item, 'yearvalue', item.classesinfo.Year + item.classesinfo.Season) //默认季节
          this.$set(item, 'classoptions', classoptions)  //将次数据
          this.$set(item, 'classvalue', classvalue)  //默认将次值
          this.$set(item, 'classscorecur', classscorecur)  //默认将次的数据
          // 图表数据渲染
          this.initechart(item, index)
        })
        this.scorelist = score;
      } else if (res.flag == '0') { //查询没课程
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          showClose: false,
          type: 'error',
          callback: action => {
            this.changeyear(this.yearoptions[0])
          }
        });
      } else if (res.flag == '2') { //学生无成绩
        this.errorflag = true;
        this.errortips = res.msg
      } else if (res.flag == '-1') {
        this.$store.dispatch("logout", {
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          showClose: false,
          type: 'error',
        });
      }
    },
    initechart (datainfo, index) {
      let lessonarr = datainfo.Lessonarr;
      let last = lessonarr[lessonarr.length - 1]
      let seriesdata = [{
        name: "全市",
        type: "line",
        color: ["#6f72ff "],
        data: [],
        connectNulls: true,
        type: "line"
      },
      {
        name: "全校",
        type: "line",
        color: ["#ff6f76"],
        data: [],
        connectNulls: true,
        type: "line"
      },
      {
        name: "全班",
        type: "line",
        color: ["#54c8e9"],
        data: [],
        connectNulls: true,
        type: "line"
      }];

      seriesdata[0].data = datainfo.Cityarr;
      seriesdata[1].data = datainfo.Campusarr;
      seriesdata[2].data = datainfo.Classarr;
      if (last > 1) {
        if (lessonarr.length == 1) {
          lessonarr.unshift('1')
          lessonarr.push(last + 2)
          seriesdata[0].data.unshift('');
          seriesdata[1].data.unshift('');
          seriesdata[2].data.unshift('');
          seriesdata[0].data.push('');
          seriesdata[1].data.push('');
          seriesdata[2].data.push('');
        } else if (lessonarr[0] != 1) {
          lessonarr.unshift('1')
          seriesdata[0].data.unshift('');
          seriesdata[1].data.unshift('');
          seriesdata[2].data.unshift('');
        }
      } else {
        lessonarr.push(last + 2)
        seriesdata[0].data.push('');
        seriesdata[1].data.push('');
        seriesdata[2].data.push('');
      }
      datainfo.AverageStorge = {
        xAxisdata: lessonarr,
        seriesdata: seriesdata,
      };
      this.hashook = true;
      setTimeout(() => {
        this.setChart(datainfo.AverageStorge, index);
      }, 100)
    },
    setChart (AverageStorge, index) {
      var echarts = require("echarts");
      var myChart = echarts.init(document.getElementById('echart' + index));
      myChart.setOption({
        legend: {
          data: ['全市', '全校', '全班'],
          x: 'right',
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            var result = params[0].name + "<br>";
            params.forEach(function (item) {
              if (item.value) {
                result += item.marker + " " + item.seriesName + " : " + item.value + "%</br>";
              } else {
                result += item.marker + " " + item.seriesName + " : - </br>";
              }
            });
            return result;
          },

        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {
              show: false
            }
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#5d5d5d"
            }
          },
          data: AverageStorge.xAxisdata
        },
        yAxis: {
          axisLine: {
            lineStyle: {
              color: "#5d5d5d"
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed"
            }
          },
          type: "value"
        },
        series: AverageStorge.seriesdata,
      })
    },
    //选择季节后数据
    changeyear (item, index) {
      let prames = {
        userid: this.userid,
        YearSeason: item.yearvalue
      }
      setTimeout(() => {
        this.initdata(prames)
      }, 100)
    },
    //切换将次
    changeLesson (item, index) {
      this.lessoninit(item, index)
    },
    lessoninit (item, index) {
      let classvalue = item.classvalue;
      let classscorecur = {};
      let errorlist = [];
      item.classscore.forEach(ele => {
        if (classvalue == ele.LessonNo) {
          classscorecur = ele;
          if (ele.ScoreQbq.length > 0) {
            for (var i = 0; i < ele.ScoreQbq.length; i = i + 4) {
              errorlist.push(ele.ScoreQbq.slice(i, i + 4));
            }
          }

        }
      })
      classscorecur.errorlist = errorlist;
      this.$set(item, 'classvalue', classvalue)  //默认将次值
      this.$set(item, 'classscorecur', classscorecur)  //默认将次的数据
    }
  },

}
</script>
<style scoped lang="scss">
.achievementMain {
  font-size: 18px;
  color: #343434;
}
.padd {
  padding: 25px 15px;
}
.achievement {
  border: 1px solid #e7e7e7;
  margin-bottom: 20px;
  position: relative;
  .top {
    text-align: center;
    margin: 20px 0 50px 0;
    span {
      margin: 0 10px;
    }
    .name {
      font-size: 26px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  .average {
    display: flex;
    .averagetit {
      flex: 1;
    }
    p {
      flex: 4.5;
      display: flex;
    }
    span.bold {
      flex: 1;
    }
    span.last {
      margin: 0;
    }
  }
}
.timetip {
  margin: 20px 0 20px 15px;
  span {
    color: #6f72ff;
  }
}
.errorlist {
  border: 1px solid #e7e7e7;
  margin-bottom: 20px;
  li {
    display: flex;
    align-items: center;
    p {
      width: 25%;
    }
  }
  .el-icon-check,
  .el-icon-close {
    font-weight: bold;
    font-size: 20px;
    margin-left: 10px;
  }
  .el-icon-check {
    color: #37c81f;
  }
  .el-icon-close {
    color: #ea1e36;
  }
}
.record {
  border: 1px solid #e7e7e7;
  padding: 25px 15px;
  .recordtit {
    font-size: 16px;
    text-align: center;
  }
  .echart {
    margin: 0 auto;
  }
  .linetips {
    font-size: 14px;
    text-align: center;
    color: #343434;
    span {
      color: #929292;
    }
  }
}
</style>
<style lang="scss">
.selectmain {
  position: absolute;
  right: 30px;
  top: 90px;
  border: 1px solid #ccc;
  border-radius: 30px;
  overflow: hidden;
  .el-input__inner {
    border: none !important;
    width: 120px;
    background: #f3f4f4;
  }
}
</style>